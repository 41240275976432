<template>
  <div class="dataTables_wrapper" :style="wrapperStyle">
    <!-- Entries input and search -->
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div v-if="pagination">
          <datatable-select @getValue="updateEntries" :options="options" />
        </div>
      </div>

      <div v-if="searching" class="col-sm-12 col-md-6">
        <datatable-search @getValue="updateSearch" />
      </div>
    </div>
    <!-- Entries input and search -->

    <!-- Main table -->
    <tbl v-if="!scrollY" v-bind="tableProps" sm datatable>
      <tbl-head :color="headerColor" :textWhite="headerWhite">
        <tr>
          <th v-for="column in columns" :key="column.field" class="th-sm sorting" v-on:click="sort(column.field, column.sort)">
            {{unCamelCase(column.label)}}
            <font-awesome-icon icon="sort" />
          </th>
          <th v-if="editable">Edit</th>
        </tr>
      </tbl-head>
      <tbl-body>
        <tr v-for="row in pages[activePage]" :key="row.id">
          <td contenteditable="false" @blur="(event) => update(rowIndex, fieldIndex, event)" @click="rowClicked(row)"
            v-for="(value, fieldIndex) in row" :key="`field-${fieldIndex}`">
            {{getRowValue(value, fieldIndex)}}
          </td>
          <td v-if="editable"><button @click='editRow(row)' type='button' class='btn btn-success'>Edit</button></td>
        </tr>
        <tr v-if="!pages.length">
          <td :colspan="columns.length">No matching records found</td>
        </tr>
      </tbl-body>

    </tbl>
    <!-- Main table -->

    <!-- ScrollY table -->
    <div v-if="scrollY" class="dataTables_scroll">
      <div v-if="scrollY" class="dataTables_scrollHead" style="padding-right: 15px">
        <div class="dataTables_scrollHeadInner">
          <tbl v-bind="tableProps" sm datatable>
            <tbl-head :color="headerColor" :textWhite="headerWhite">
              <tr>
                <th v-for="column in columns" :key="column.field" class="th-sm sorting" v-on:click="sort(column.field, column.sort)">
                  {{column.label}}
                  <font-awesome-icon icon="sort" />
                </th>
              </tr>
            </tbl-head>
          </tbl>
        </div>
      </div>
      <tbl v-bind="tableProps" sm datatable>
        <tbl-body>
          <tr v-for="row in pages[activePage]" :key="row.id">
            <td v-for="(value, key) in row" :key="key">
              {{value}}
            </td>
          </tr>
          <tr v-if="!pages.length">
            <td :colspan="columns.length">No matching records found</td>
          </tr>
        </tbl-body>
      </tbl>
      <div class="dataTables_scrollFoot" style="padding-right: 15px">
        <div class="dataTables_scrollFootInner">
          <tbl v-bind="tableProps" sm datatable>
            <tbl-head tag="tfoot">
              <tr>
                <th v-for="column in columns" :key="column.field+'_foot'" class="th-sm sorting">
                  {{column.label}}
                </th>
              </tr>
            </tbl-head>
          </tbl>
        </div>
      </div>
    </div>
    <!-- ScrollY table -->

    <!-- Labels and pagination -->
    <div v-if="pagination" class="row">
      <div class="col-sm-12 col-md-5">
        <div class="dataTables_info" role="status" aria-live="polite">
          Showing {{activePage > 0 ? activePage*entries : activePage+1}} to {{pages.length-1 > activePage ?
          pages[activePage].length*(activePage+1) : filteredRows.length}} of {{filteredRows.length}} entries
        </div>
      </div>
      <div class="col-sm-12 col-md-7">
        <div class="dataTables_paginate float-right">

          <pagination id="pagination" v-if="pages.length <= 10">
            <page-item v-on:click.native="changePage(activePage-1)" :disabled="activePage === 0 ? true : false">
              Previous
            </page-item>
            <page-item v-for="(page, index) in pages" :key="index" v-on:click.native="changePage(index)" :active="activePage === index ? true : false">
              {{index+1}}
            </page-item>
            <page-item v-on:click.native="changePage(activePage+1)" :v-if="showNext">
              Next
            </page-item>
          </pagination>

          <pagination id="pagination" v-if="pages.length > 10">
            <page-item v-on:click.native="changePage(activePage-1)" :disabled="activePage === 0 ? true : false">
              Previous
            </page-item>
            <page-item v-for="(page, index) in getPageRange(pages, activePage)" :key="index" v-on:click.native="changePage(page.index)"
              :active="activePage === page.index ? true : false">
              {{page.index+1}}
            </page-item>
            <page-item v-on:click.native="changePage(activePage+1)" :v-if="showNext">
              Next
            </page-item>
          </pagination>
        </div>
      </div>
    </div>
    <!-- Labels and pagination -->

  </div>
</template>

<script>
  import Tbl from './Table';
  import TblHead from './TableHead';
  import TblBody from './TableBody';
  import Pagination from './Pagination';
  import PageItem from './PageItem';
  import mdbInput from './Input';
  import DatatableSelect from './DatatableSelect';
  import DatatableSearch from './DatatableSearch';
  import moment from 'moment';

  const Datatable = {
    name: 'Datatable',
    props: {
      data: {
        type: [Object, String],
        default: () => ({
          columns: [],
          rows: []
        })
      },
      editable: {},
      dataItems: {
        type: Array
      },
      dataColumns: {
        type: Array
      },
      autoWidth: {
        type: Boolean,
        default: true
      },
      bordered: {
        type: Boolean,
        default: false
      },
      borderless: {
        type: Boolean,
        default: false
      },
      dark: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      },
      headerColor: {
        type: String
      },
      headerWhite: {
        type: Boolean,
        default: false
      },
      hover: {
        type: Boolean,
        default: false
      },
      materialInputs: {
        type: Boolean,
        default: false
      },
      maxWidth: {
        type: String
      },
      maxHeight: {
        type: String
      },
      order: {
        type: Array
      },
      pagination: {
        type: Boolean,
        default: true
      },
      responsive: {
        type: Boolean,
        default: false
      },
      responsiveSm: {
        type: Boolean,
        default: false
      },
      responsiveMd: {
        type: Boolean,
        default: false
      },
      responsiveLg: {
        type: Boolean,
        default: false
      },
      responsiveXl: {
        type: Boolean,
        default: false
      },
      scrollY: {
        type: Boolean,
        defautl: false
      },
      searching: {
        type: Boolean,
        default: true
      },
      sorting: {
        type: Boolean,
        default: true
      },
      striped: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        rows: this.dataItems || [],
        columns: this.dataColumns || [],
        options: [10, 25, 50, 100],
        entries: 10,
        pages: [],
        activePage: 0,
        search: '',
        tableProps: {
          autoWidth: this.autoWidth,
          bordered: this.bordered,
          borderless: this.borderless,
          dark: this.dark,
          fixed: this.fixed,
          hover: this.hover,
          responsive: this.responsive,
          responsiveSm: this.repsonsiveSm,
          responsiveMd: this.repsonsiveMd,
          responsiveLg: this.responsiveLg,
          responsiveXl: this.responsiveXl,
          striped: this.striped,
          dtScrollY: this.scrollY,
          maxHeight: this.maxHeight
        },
        wrapperStyle: {
          maxWidth: this.maxWidth ? this.maxWidth : '100%',
          margin: '0 auto'
        }
      };
    },
    components: {
      DatatableSearch,
      DatatableSelect,
      Tbl,
      TblHead,
      TblBody,
      Pagination,
      PageItem,
      mdbInput
    },
    computed: {
      showNext() {
        if (this.activePage < this.pages.length) {
          return true
        }
        return false
      },
      // filter objects by parameters match
      filteredRows() {
        return this.rows.filter(row => {
          for (let key in row) {
            if (Object.prototype.hasOwnProperty.call(row, key)) {
              var stringValue = "";
              if (row[key]) {
                stringValue = row[key].toString();
              }
              if (stringValue.toLowerCase().match(this.search.toLowerCase())) return true;

            }
          }
          return false;
        });
      }
    },
    methods: {
      getPageRange(pages, activePage) {
        var pageRange = [];
        for (var x = activePage - 5; x <= activePage + 5; x++) {
          if (x >= 0) {
            pageRange.push({
              page: pages[x],
              index: x
            });
          }
        }

        return pageRange
      },
      upperCase(str) {
        return str.toUpperCase();
      },
      lowerCase(str) {
        return str.toLowerCase();
      },
      properCase(str) {
        return this.lowerCase(str).replace(/^\w|\s\w/g, this.upperCase);
      },
      unCamelCase(str) {

        str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2');
        str = str.toLowerCase(); //add space between camelCase text

        return this.properCase(str);
      },
      getRowValue(value, fieldIndex) {
        if (fieldIndex.toLowerCase().includes('date')) {
          return new moment(value).format('YYYY-MM-DD')
        }
        return value
      },
      editRow(data) {
        this.$emit('editRow', data);
      },
      rowClicked(data) {
        this.$emit('rowClicked', data);
      },
      changePage(index) {
        this.activePage = index;
      },
      sort(field, sort) {
        if (this.sorting) {
          sort === 'asc' ?
            this.rows.sort((a, b) => (a[field] > b[field] ? 1 : -1)) :
            this.rows.sort((a, b) => (a[field] > b[field] ? -1 : 1));
          this.columns[this.columns.findIndex(column => column.field === field)].sort = sort === 'asc' ? 'desc' :
            'asc';
        }
      },
      updateEntries(value) {
        this.entries = value;
      },
      updateSearch(value) {
        this.search = value;
      },
      update(row, field, event) {
        let newRow = {
          ...this.mutableRows[row],
          [field]: event.target.innerText
        };
        this.mutableRows.splice(row, 1, newRow);
      }
    },
    mounted() {
      // bind data or download form API
      if (typeof this.data === 'string') {
        fetch(this.data)
          .then(res => res.json())
          .then(json => {
            this.columns = json.columns;
            this.rows = json.rows;
            this.$emit('fields', this.columns);
          })
          .catch(err => console.log(err));
      }

      // findout rows amount, and slice it into array (split into pages)
      const pagesAmount = Math.ceil(this.filteredRows.length / this.entries);
      this.pages = [];
      if (this.pagination) {
        for (let i = 1; i <= pagesAmount; i++) {
          const pageEndIndex = i * this.entries;
          this.pages.push(this.filteredRows.slice(pageEndIndex - this.entries, pageEndIndex));
        }
      } else {
        this.pages.push(this.filteredRows);
      }
      this.activePage = 0;

      // initial sorting
      if (this.order) {
        this.sort(this.columns[this.order[0]].field, this.order[1]);
      }

      this.$emit('pages', this.pages);
      this.$emit('fields', this.columns);
    },
    watch: {
      dataItems() {
        this.rows = this.dataItems;
      },
      entries() {
        // do the split every entry change (changing entries amount)
        const pagesAmount = Math.ceil(this.filteredRows.length / this.entries);
        this.pages = [];
        for (let i = 1; i <= pagesAmount; i++) {
          const pageEndIndex = i * this.entries;
          this.pages.push(this.filteredRows.slice(pageEndIndex - this.entries, pageEndIndex));
        }
        this.activePage = this.activePage < this.pages.length ? this.activePage : this.pages.length - 1;

        this.$emit('pages', this.pages);
      },
      filteredRows() {
        // do the split on every change in rows (searching)
        const pagesAmount = Math.ceil(this.filteredRows.length / this.entries);
        this.pages = [];
        if (this.pagination) {
          for (let i = 1; i <= pagesAmount; i++) {
            const pageEndIndex = i * this.entries;
            this.pages.push(this.filteredRows.slice(pageEndIndex - this.entries, pageEndIndex));
          }
        } else {
          this.pages.push(this.filteredRows);
        }
        this.activePage = 0;

        this.$emit('pages', this.pages);
      }
    }
  };

  export default Datatable;
  export {
    Datatable as Datatable
  };

</script>

<style scoped>
  .dataTables-scrollBody {
    display: block;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .dataTables-scrollBody td,
  .dataTables-scrollBody th {
    white-space: nowrap;
  }

</style>

<style>
  .dataTables_wrapper select {
    display: inline-flex !important;
    width: auto;
  }

  .dataTables_wrapper input {
    display: inline-flex !important;
    width: auto;
  }

</style>